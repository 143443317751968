import React from "react"
import styled from "styled-components"
import BestelNuOok from "./images/bestel-nu-ook"
import poster from "../images/poster.png"
import werkblad from "../images/werkblad.jpg"
import { Link as ScrollLink } from "react-scroll"

const Section = styled.div`
  color: #4a8591;
  background-image: radial-gradient(
    circle at bottom center,
    #ffffff 0%,
    #d2f7fe 100%
  );
`

export default function OtherProductsWrapper() {
  return (
    <Section name="andere-producten" className="pb-0 pb-lg-5">
      <div className="container container-fluid">
        <div className="row">
          <div className="mx-auto col-12 col-lg-10 py-3 py-sm-5">
            <div
              className="mx-auto my-3"
              style={{ maxWidth: 258, maxHeight: 29 }}
            >
              <BestelNuOok />
            </div>
            <div>
              <img
                src={poster}
                alt="De Poster vol Talent is een kleurrijke poster waarop iedereen met autisme zijn talenten kan ontdekken."
                className="my-1 float-right ml-5"
                style={{
                  width: 240,
                  height: 417,
                  objectFit: "cover",
                }}
              />
              <p className="mb-4 lead">
                De <strong>Poster vol Talent</strong> is een kleurrijke poster
                waarop iedereen met autisme zijn talenten kan ontdekken.
              </p>
              <p className="mb-4">
                De Expertgroep Autisme &amp; Onderwijs pleit voor ‘ondersteunen
                en toerusten’ van kinderen met autisme in het onderwijs. Suzanne
                Agterberg: “Dit betekent dat we kinderen niet alleen moeten
                leren wat autisme soms allemaal moeilijk maakt—en welke hulp ze
                daarbij nodig hebben—(ondersteunen), maar vooral ook samen
                moeten kijken naar welke vaardigheden je kunt gebruiken om je
                eigen weg te vinden (toerusten). Deze poster en het spel zijn
                daar mooie hulpmiddelen voor!”
              </p>
              <p className="mb-4">
                1 poster = &euro;12 per stuk
                <br />
                5 posters = &euro;11 per stuk
                <br />
                10 posters = &euro;10 per stuk
                <br />
                Prijzen zijn exclusief verzendkosten.
              </p>
              <p className="mb-4">
                <ScrollLink
                  href="#"
                  //  rulesdir/privateModelMethods
                  to="bestel-nu"
                  smooth={true}
                  duration={500}
                  className="btn btn-primary py-2 px-4 mb-2 mt-0 font-weight-bold text-uppercase"
                  style={{
                    border: 0,
                  }}
                >
                  Bestel de poster hier
                </ScrollLink>
              </p>
            </div>
            <hr />
            <div>
              <img
                src={werkblad}
                alt="Het Werkblad vol Talent is ..."
                className="my-1 float-left mr-5"
                style={{
                  width: 240,
                  height: 320,
                  objectFit: "cover",
                }}
              />
              <p className="mb-4 lead">
                <strong>Werkblad vol Talent</strong>
              </p>
              <p className="mb-4">
                Wil je je als leerkracht, ouder of begeleider anders leren kijken naar kinderen met autisme? De focus richten op wat ze wel kunnen? Wil je kinderen laten groeien door ze een ingevuld werkblad met talenten mee te geven? Bestel dan het werkblad vol talent. Een duidelijk overzicht van alle talenten die je aan kan kruisen en mee kan geven voor meer vertrouwen en groei.
              </p>
              <p className="mb-4">
                Het Werkblad vol Talent is per 5 te koop samen met een Doosje vol Talent, of per 25 als je bijvoorbeeld voor een hele klas de talenten goed in kaart wil brengen.
              </p>
              <p className="mb-4">
                5 werkbladen = &euro;7,50
                <br />
                25 werkbladen = &euro;30
                <br />
                Prijzen zijn exclusief verzendkosten.
              </p>
              <p className="mb-4">
                <ScrollLink
                  href="#"
                  //  rulesdir/privateModelMethods
                  to="bestel-nu"
                  smooth={true}
                  duration={500}
                  className="btn btn-primary py-2 px-4 mb-2 mt-0 font-weight-bold text-uppercase"
                  style={{
                    border: 0,
                  }}
                >
                  Bestel de werkbladen hier
                </ScrollLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
